<template>
  <layout :loading="loading">
    <div class="library-settigs">
      <div class="library-settigs__head">
        <b-button
          variant="secondary"
          class="library-settigs__back-button"
          @click.prevent="goToLibraryPage"
        >
          <i class="bx bx-arrow-back font-size-16" />
        </b-button>
        <div class="library-settigs__title">Library Settings</div>
      </div>
      <b-card class="library-settigs__card">
        <b-tabs
          content-class="tab-container text-muted"
          nav-class="nav-tabs-custom"
          v-model="tabIndex"
        >
          <b-tab>
            <template v-slot:title>Pro Categories</template>
            <library-settings-list
              :list="proCategories"
              @onEdit="handleEdit"
              @onDelete="handleDelete"
            />
          </b-tab>
          <b-tab>
            <template v-slot:title>Categories</template>
            <library-settings-list
              :list="defaultCategories"
              @onEdit="handleEdit"
              @onDelete="handleDelete"
            />
          </b-tab>
          <b-tab>
            <template #title>Languages</template>
            <library-settings-list
              :list="languages"
              @onEdit="handleEdit"
              @onDelete="handleDelete"
            />
          </b-tab>
        </b-tabs>
        <b-button
          variant="success"
          size="sm"
          class="library-settigs__create-button"
          @click="handleCreate"
        >
          <i class="bx bx-plus" />
          Add
        </b-button>
      </b-card>
    </div>
    <library-settings-modal
      :open="isModalOpen"
      :mode="mode"
      :initialValue="title"
      @onHide="closeModal"
      @onSubmit="handleSubmit"
    />
  </layout>
</template>

<script>
import { mapActions } from 'vuex';
import { FORM_MODE } from '@/utils/constants';
import LibrarySettingsList from '@/components/library-settings-list.vue';
import LibrarySettingsModal from '@/components/library-settings-modal.vue';

export default {
  name: 'LibrarySettings',
  components: {
    LibrarySettingsList,
    LibrarySettingsModal
  },
  data: () => ({
    title: '',
    tabIndex: 0,
    loading: false,
    isModalOpen: false,
    currentSettingId: null,
    mode: FORM_MODE.CREATE
  }),
  methods: {
    ...mapActions('library', [
      'getLibraryLanguages',
      'getLibraryCategories',
      'createLibraryCategory',
      'updateLibraryCategory',
      'deleteLibraryCategory',
      'createLibraryLanguage',
      'updateLibraryLanguage',
      'deleteLibraryLanguage',
    ]),
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.title = '';
      this.isModalOpen = false;
    },
    handleCreate() {
      this.mode = FORM_MODE.CREATE;
      this.openModal();
    },
    handleEdit({ id, title }) {
      this.title = title;
      this.mode = FORM_MODE.EDIT;
      this.currentSettingId = id;
      this.openModal();
    },
    handleDelete({ id }) {
      if (id) {
        this.$bvModal.msgBoxConfirm(`Delete this ${this.tabIndex !== 2 ? 'category' : 'language'}?`, {
          title: 'Please Confirm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(async (value) => {
            if (value) {
              this.loading = true;
              await this.deleteSetting(id);
              await this.fetchSettings();
              this.loading = false;
            }
          });
      }
    },
    async handleSubmit(title) {
      this.loading = true;
      this.closeModal();
      const data = { title };
      if (this.tabIndex === 0 || this.tabIndex === 1) {
        data.is_pro = this.tabIndex === 0;
      }
      if (this.mode === FORM_MODE.CREATE) {
        await this.createSetting(data);
      } else {
        await this.updateSetting({ data, id: this.currentSettingId });
      }
      await this.fetchSettings();
      this.loading = false;
      this.currentSettingId = null;
    },
    goToLibraryPage() {
      this.$router.push({ name: 'library' });
    },
  },
  computed: {
    categories() {
      return this.$store.state.library.categories;
    },
    proCategories() {
      return this.categories.filter(({ is_pro }) => is_pro);
    },
    defaultCategories() {
      return this.categories.filter(({ is_pro }) => !is_pro);
    },
    languages() {
      return this.$store.state.library.languages;
    },
    createSetting() {
      return this.tabIndex !== 2 ? this.createLibraryCategory : this.createLibraryLanguage;
    },
    updateSetting() {
      return this.tabIndex !== 2 ? this.updateLibraryCategory : this.updateLibraryLanguage;
    },
    deleteSetting() {
      return this.tabIndex !== 2 ? this.deleteLibraryCategory : this.deleteLibraryLanguage;
    },
    fetchSettings() {
      return this.tabIndex !== 2 ? this.getLibraryCategories : this.getLibraryLanguages;
    }
  },
  watch: {
    tabIndex: {
      async handler() {
        this.loading = true;
        await this.fetchSettings();
        this.loading = false;
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.library-settigs {
  color: #2A3042;

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 20.5px;
  }

  &__create-button {
    width: 81px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 13px;
      margin-right: 10px;
    }
  }

  &__back-button {
    width: 36.53px;
    height: 36.53px;
    position: relative;
    border: none;
    margin-right: 20px;
    background-color: #BBB;

    &:active {
      background-color: #BBB !important;
    }

    i {
      top: 50%;
      left: 50%;
      color: #fff;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }
  &__title {
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
  }
  &__card {
    border-radius: 20px;
  }
}
</style>

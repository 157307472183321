<template>
  <b-table
    hover
    class="mt-3"
    :items="list"
    :fields="fields"
    stacked="md"
    thead-class="d-none"
  >
    <template #cell(actions)="{ item }">
      <div class="d-flex align-items-center justify-content-end font-size-20">
        <i
          class="bx bx-pencil text-warning mr-2 cursor-pointer"
          @click="$emit('onEdit', item)"
        />
        <i
          class="bx bx-trash text-danger cursor-pointer"
          @click="$emit('onDelete', item)"
        />
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'LibrarySettingsList',
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    fields: [
      {
        key: 'title',
        label: 'Title',
        tdClass: 'border-0 p-2',
      },
      {
        key: 'actions',
        label: 'Actions',
        tdClass: 'border-0 p-2',
        thStyle: { width: '70px' }
      }
    ]
  })
}
</script>

<template>
  <b-modal
    :id="modalId"
    size="md"
    :title="modalTitle"
    centered
    scrollable
    title-class="font-22"
    @hide="handleHideModal"
  >
    <b-form-group
      label="Title"
      label-for="title"
      class="required"
    >
      <b-form-input
        id="title"
        v-model="title"
        :class="{ 'is-invalid': $v.title.$error }"
      />
    </b-form-group>
    <template slot="modal-footer">
      <div class="w-100 d-flex justify-content-end">
        <b-button
          variant="primary"
          @click="handleSubmit"
        >
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { FORM_MODE } from '@/utils/constants';

export default {
  name: 'BookModal',
  props: {
    open: Boolean,
    mode: {
      type: String,
      required: true,
      validator(value) {
        return [FORM_MODE.CREATE, FORM_MODE.EDIT].includes(value)
      }
    },
    initialValue: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    modalId: 'library-settings-modal',
    title: '',
  }),
  validations: {
    title: { required },
  },
  methods: {
    handleHideModal() {
      this.$emit('onHide');
    },
    handleSubmit() {
      this.$v.title.$touch();
      if (!this.$v.title.$anyError) {
        this.$emit('onSubmit', this.title);
      }
    },
  },
  computed: {
    modalTitle() {
      return this.mode === FORM_MODE.CREATE ? 'Create' : 'Edit';
    }
  },
  watch: {
    open(val) {
      if (val) {
        if (this.mode === FORM_MODE.EDIT) {
          this.title = this.initialValue;
        }
        this.$bvModal.show(this.modalId);
      } else {
        this.$bvModal.hide(this.modalId);
        this.$v.$reset();
        this.title = '';
      }
    },
  }
}
</script>
